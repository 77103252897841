.header-img {
  object-fit: cover;
  width: 100%;
  height: 250px;
}

.description-field {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
