.result-item {
  display: flex;
  align-items: center;
  margin: 5px;
  cursor: pointer;
  border-color: black;
  position: "fixed";
  background-color: #fff0;
  width: 305px;
}

.result-item img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 0.375rem;
}

.results-list {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #d8d1d1;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
  position: static;
}

.link_cursor {
  cursor: pointer;
}
